import { HsbApi } from '@/api'
import { ProjectId } from '@/model'
import { defineStore } from 'pinia'
import { v4 } from 'uuid'
import { StudyCaseId } from '@/model/study-case'
import { StudyCase } from '@gridside/hsb-api'
import { Writeable } from '@util/type-helpers'

const StudyCaseApi = HsbApi.studyCases

export const useStudyCaseStore = defineStore('studyCase', {
  state: () => ({
    itemsById: {} as Record<StudyCaseId, StudyCase>,
    loaded: false,
    loading: false,
    loadedProject: undefined as ProjectId | undefined,
    selection: [] as StudyCaseId[]
  }),

  getters: {
    findById(state) {
      return (id: StudyCaseId): StudyCase | undefined => state.itemsById[id]
    },
    items(): StudyCase[] {
      return Object.values(this.itemsById)
    },
    projectId(): ProjectId {
      if (this.loadedProject) {
        return this.loadedProject
      }
      throw Error('StudyCase store not loaded!')
    }
  },

  actions: {
    async delete(id: StudyCaseId) {
      const studyCase = this.findById(id)
      if (studyCase) {
        await StudyCaseApi.deleteStudyCase(this.projectId, studyCase.id)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },
    async load(projectId: ProjectId) {
      this.loading = true
      const itemsById: Record<StudyCaseId, StudyCase> = {}
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('StudyCaseStore.load: ProjectId must not be empty.')
        }

        this.loadedProject = projectId
        const studyCases = (await StudyCaseApi.getStudyCasesByProject(projectId)).results
        studyCases.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async save(item_read: StudyCase) {
      const item = item_read as Writeable<StudyCase, 'id'>
      if (!item.id) {
        item.id = v4()
      }

      const updatedItem = await StudyCaseApi.saveStudyCase(
        this.projectId,
        item.id,
        item as StudyCase
      )
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }
      return updatedItem
    }
  }
})
