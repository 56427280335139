import { h } from 'vue'
import { RouteRecordRaw, RouterView } from 'vue-router'
import mapRoutes from './map-routes'
import overheadLineRoutes from '@/router/project/overhead-line-routes'
import { prependColon, RouteParams } from '@/router/routeParams'
import { useOverheadLineStore } from '@/stores/overhead-lines'

const projectRoutes: RouteRecordRaw[] = [
  {
    path: '/projects/',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        component: () => import('@/views/ProjectsView.vue'),
        name: 'projects',
        meta: {
          title: 'Projekte'
        }
      },
      {
        path: 'create',
        component: () => import('@/views/project/ProjectView.vue'),
        children: [
          {
            path: '',
            name: 'project-create',
            meta: {
              create: true
            },
            component: () => import('@/views/project/BaseView.vue')
          }
        ]
      },
      {
        path: prependColon(RouteParams.ProjectId),
        name: 'project-base',
        component: () => import('@/views/project/ProjectView.vue'),
        beforeEnter: async (to, _, next) => {
          // ensure that the overhead lines for this project are loaded
          const overheadLineStore = useOverheadLineStore()
          await overheadLineStore.load(to.params[RouteParams.ProjectId] as string)
          next()
        },
        redirect: { name: 'project-map' },
        children: [
          {
            path: 'edit',
            name: 'project-edit',
            component: () => import('@/views/project/BaseView.vue')
          },
          mapRoutes,
          overheadLineRoutes,
          {
            path: 'systems',
            component: () => import('@/layouts/pages/SplitView.vue'),
            meta: {
              title: 'Systeme'
            },
            children: [
              {
                path: '',
                name: 'project-systems',
                components: {
                  default: () => import('@/views/project/systems/SystemListView.vue')
                }
              },
              {
                path: 'create',
                name: 'project-system-create',
                meta: { create: true },
                components: {
                  default: () => import('@/views/project/systems/SystemListView.vue'),
                  detail: () => import('@/views/project/systems/SystemForm.vue')
                }
              },
              {
                path: ':systemId',
                name: 'project-system-edit',
                components: {
                  default: () => import('@/views/project/systems/SystemListView.vue'),
                  detail: () => import('@/views/project/systems/SystemForm.vue')
                }
              }
            ]
          },
          {
            path: 'study-case',
            component: () => import('@/layouts/pages/SplitView.vue'),
            props: { maxWidth: '48rem', useMaxWidth: true },
            meta: {
              title: 'Berechnungsfälle'
            },
            children: [
              {
                path: '',
                name: 'project-study-cases',
                components: {
                  default: () => import('@/views/project/study-cases/StudyCaseListView.vue')
                }
              },
              {
                path: 'create',
                name: 'project-study-case-create',
                meta: { create: true },
                components: {
                  default: () => import('@/views/project/study-cases/StudyCaseListView.vue'),
                  detail: () => import('@/views/project/study-cases/StudyCaseDetail.vue')
                }
              },
              {
                path: prependColon(RouteParams.StudyCaseId),
                name: 'project-study-case-edit',
                components: {
                  default: () => import('@/views/project/study-cases/StudyCaseListView.vue'),
                  detail: () => import('@/views/project/study-cases/StudyCaseDetail.vue')
                }
              }
            ]
          },
          {
            path: 'conductor-allocations',
            component: () => import('@/layouts/pages/SplitView.vue'),
            props: { maxWidth: '36rem' },
            meta: {
              title: 'Leiterzuordnungen'
            },
            children: [
              {
                path: '',
                name: 'project-conductor-allocations',
                components: {
                  default: () =>
                    import('@/views/project/conductor-allocations/ConductorAllocationListView.vue')
                }
              },
              {
                path: 'create',
                name: 'project-conductor-allocation-create',
                meta: { create: true },
                components: {
                  default: () =>
                    import('@/views/project/conductor-allocations/ConductorAllocationListView.vue'),
                  detail: () =>
                    import('@/views/project/conductor-allocations/ConductorAllocationDetail.vue')
                }
              },
              {
                path: ':id',
                name: 'project-conductor-allocation-edit',
                components: {
                  default: () =>
                    import('@/views/project/conductor-allocations/ConductorAllocationListView.vue'),
                  detail: () =>
                    import('@/views/project/conductor-allocations/ConductorAllocationDetail.vue')
                }
              }
            ]
          },
          {
            path: 'media-group',
            component: () => import('@/layouts/pages/SplitView.vue'),
            props: { maxWidth: '38rem' },
            meta: {
              title: 'Mediengruppen'
            },
            children: [
              {
                path: '',
                name: 'project-media-group',
                components: {
                  default: () => import('@/views/project/media-groups/MediaGroupListView.vue')
                }
              },
              {
                path: prependColon(RouteParams.MediaGroupId),
                name: 'project-media-group-edit',
                components: {
                  default: () => import('@/views/project/media-groups/MediaGroupListView.vue'),
                  detail: () => import('@/views/project/media-groups/MediaGroupDetail.vue')
                }
              },
              {
                path: 'import',
                name: 'project-media-group-import',
                meta: {
                  title: 'Mediengruppen importieren'
                },
                components: {
                  default: () => import('@/views/project/media-groups/MediaGroupListView.vue'),
                  overlay: () =>
                    import('@/views/project/media-groups/MediaGroupImportWizardView.vue')
                }
              }
            ]
          },
          {
            path: 'tower-types',
            component: () => import('@/layouts/pages/SplitView.vue'),
            children: [
              {
                path: '',
                name: 'project-towertypes',
                component: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
                props: true,
                meta: { title: 'Masttypen' }
              },
              {
                path: ':id',
                name: 'project-towertypes-edit',
                components: {
                  default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
                  detail: () => import('@/views/library/tower-type/TowerTypeForm.vue')
                },
                props: true
              },
              {
                path: 'create',
                name: 'project-towertypes-create',
                components: {
                  default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
                  detail: () => import('@/views/library/tower-type/TowerTypeForm.vue')
                },
                props: true
              }
            ]
          },
          {
            path: 'conductor-types',
            component: () => import('@/layouts/pages/SplitView.vue'),
            children: [
              {
                path: '',
                name: 'project-conductortypes',
                components: {
                  default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue')
                },
                props: true,
                meta: { title: 'Leitertypen' }
              },
              {
                path: ':id',
                name: 'project-conductortypes-edit',
                components: {
                  default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
                  detail: () => import('@/views/library/conductor-type/ConductorTypeForm.vue')
                },
                props: true,
                meta: { title: 'Leitertypen' }
              },
              {
                path: 'create',
                name: 'project-conductortypes-create',
                components: {
                  default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
                  detail: () => import('@/views/library/conductor-type/ConductorTypeForm.vue')
                },
                props: true,
                meta: { title: 'Leitertypen' }
              }
            ]
          },
          {
            path: 'pipe-types',
            component: () => import('@/layouts/pages/SplitView.vue'),
            children: [
              {
                path: '',
                name: 'project-pipetypes',
                components: {
                  default: () => import('@/views/library/pipe-type/PipeTypeListView.vue')
                },
                props: true,
                meta: { title: 'Rohrleitungstypen' }
              },
              {
                path: ':id',
                name: 'project-pipetypes-edit',
                components: {
                  default: () => import('@/views/library/pipe-type/PipeTypeListView.vue'),
                  detail: () => import('@/views/library/pipe-type/PipeTypeForm.vue')
                },
                props: true,
                meta: { title: 'Rohrleitungstypen' }
              },
              {
                path: 'create',
                name: 'project-pipetypes-create',
                components: {
                  default: () => import('@/views/library/pipe-type/PipeTypeListView.vue'),
                  detail: () => import('@/views/library/pipe-type/PipeTypeForm.vue')
                },
                props: true,
                meta: { title: 'Rohrleitungstypen' }
              }
            ]
          }
        ]
      }
    ]
  }
]
export default projectRoutes
