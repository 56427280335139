<script lang="ts">
import { useProject } from '@/composables/useProject'
import { defineComponent } from 'vue'
import { StudyCaseId } from '@/model'
import { useCalculationStore } from '@/stores/calculation'
import { HsbApi } from '@/api'
import {
  CalculationId,
  CalculationStatusTranslations,
  CalculationStatusValue
} from '@/model/calculation'
import { PDialog } from '@prionect/ui'
import { ElSelect } from 'element-plus'
import { SelectItem } from '@/components/form/Field.vue'
import { useStudyCaseStore } from '@/stores/study-case'
import { RouteParams } from '@/router/routeParams'
import { CalculationStatusStatus } from '@gridside/hsb-api'

const CalculationApi = HsbApi.calculation
const StudyCaseApi = HsbApi.studyCases

export default defineComponent({
  name: 'CalculationLauncher',
  components: { PDialog, ElSelect },

  data: () => ({
    selectedStudyCaseId: undefined as StudyCaseId | undefined,
    clearingCalculation: false,
    showClearConfirm: false,
    RouteParams,
    CalculationStatusTranslations
  }),

  computed: {
    calculationStudyCaseId(): StudyCaseId | undefined {
      return this.calculationStore.calculationStatus?.studyCase
      //|| this.calculationStore.result?.studyCase @FIXME adapt to new API
    },

    isProcessing(): boolean {
      if (this.status) {
        return [
          CalculationStatusStatus.PENDING,
          CalculationStatusStatus.INITIALIZING,
          CalculationStatusStatus.RUNNING
        ].some((status) => status === this.status)
      }
      return false
    },

    studyCaseItems(): SelectItem[] {
      const items = this.studyCaseStore.items
      return items.map((item) => ({
        value: item.id,
        label: item.name
      }))
    },

    isRecalculation(): boolean {
      return (
        !!this.calculationStore.result && this.selectedStudyCaseId === this.calculationStudyCaseId
      )
    },

    status(): CalculationStatusValue | undefined {
      return this.calculationStore.calculationStatus?.status
    }
  },

  setup() {
    const calculationStore = useCalculationStore()
    const studyCaseStore = useStudyCaseStore()
    const { projectId } = useProject()

    calculationStore.ensureLoaded(projectId.value)
    studyCaseStore.ensureLoaded(projectId.value)

    return { projectId, calculationStore, studyCaseStore }
  },

  mounted() {
    if (this.calculationStudyCaseId) {
      this.selectedStudyCaseId = this.calculationStudyCaseId
    }
  },

  watch: {
    calculationStudyCaseId() {
      if (this.calculationStudyCaseId) {
        this.selectedStudyCaseId = this.calculationStudyCaseId
      }
    },
    studyCaseItems() {
      if (this.studyCaseItems[0] && this.selectedStudyCaseId === undefined) {
        this.selectedStudyCaseId = this.studyCaseItems[0].value
      } else if (this.calculationStudyCaseId) {
        this.selectedStudyCaseId = this.calculationStudyCaseId
      }
    }
  },

  methods: {
    async cancel(calculationId: CalculationId) {
      await CalculationApi.cancelCalculation(this.projectId, calculationId)
      await this.calculationStore.ensureLoaded(this.projectId)
    },

    async clear(calculationId: CalculationId) {
      this.clearingCalculation = true
      await CalculationApi.clearCalculation(this.projectId, calculationId)
      await this.calculationStore.ensureLoaded(this.projectId)
      this.clearingCalculation = false
    },

    async clearAndStart(calculationId?: CalculationId) {
      if (!calculationId) {
        throw new Error('TODO: Implement calculationId.')
      }
      await this.clear(calculationId)
      await this.start()
    },

    onSelectedStudyCaseChanged() {
      if (this.$route.name === 'project-map-study-case-edit') {
        this.$router.push({
          name: 'project-map-study-case-edit',
          params: { id: this.selectedStudyCaseId }
        })
      }
    },

    async start() {
      this.calculationStore.showResult = false

      if (!this.selectedStudyCaseId) {
        throw new Error('Could not start calculation, no study case selected.')
      }

      const calc = await this.calculationStore.start({
        project: this.projectId,
        studyCaseId: this.selectedStudyCaseId
      })

      return calc
    }
  }
})
</script>

<template>
  <div class="flex flex-col">
    <div class="flex gap-2 mb-2">
      <el-select
        v-model="selectedStudyCaseId"
        placeholder="Select"
        aria-label="Betriebsfall"
        size="large"
        @update:modelValue="onSelectedStudyCaseChanged"
      >
        <el-option
          v-for="item in studyCaseItems"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <p-btn
        icon
        size="large"
        :disabled="selectedStudyCaseId === undefined"
        title="Betriebsfall bearbeiten"
        @click="
          $router.push({
            name: 'project-map-study-case-edit',
            params: { [RouteParams.StudyCaseId]: selectedStudyCaseId }
          })
        "
      >
        <el-icon size="20"><EditIcon /></el-icon>
      </p-btn>
    </div>

    <template v-if="isProcessing">
      <div class="my-6">
        <el-progress
          :percentage="100"
          status="success"
          :indeterminate="true"
          :duration="1"
          :show-text="false"
        />
        <div class="text-center mt-2 mb-2 text-sm font-semibold text-gray-500">
          {{ status ? CalculationStatusTranslations[status] : 'unbekannter status' }}...
        </div>
      </div>
      <p-btn class="w-full" type="warning" @click="cancel">Berechnung abbrechen</p-btn>
    </template>
    <template v-else>
      <p-btn
        :disabled="selectedStudyCaseId === undefined"
        type="primary"
        @click="start"
        @click.shift="clearAndStart()"
      >
        Berechnung
        <template v-if="isRecalculation">neu</template>
        starten
      </p-btn>
    </template>
    <div class="flex m-2">
      <el-button
        :loading="clearingCalculation"
        text
        size="small"
        class="grow"
        @click="showClearConfirm = true"
      >
        Berechnung zurücksetzen
      </el-button>
    </div>
  </div>
  <Teleport to="body">
    <p-dialog
      :show="showClearConfirm"
      title="Berechnung zurücksetzen"
      confirm-label="Fortsetzen"
      @confirm="
        clear(0) // @FIXME
      "
      @close="showClearConfirm = false"
    >
      Es werden alle Ergebnisse entfernt, HSBlib-Worker angehalten und/oder neu initialisiert.
    </p-dialog>
  </Teleport>
</template>

<style scoped lang="css"></style>
