<script lang="ts">
import CalculationModeSwitcher from '@/components/study-case/CalculationModeSwitcher.vue'
import OperationStatesTable from '@/components/study-case/OperationStatesTable.vue'
import { useProject } from '@/composables/useProject'
import { studyCase } from '@/config/fields'
import { CalculationModesSchemas, StudyCaseSchemaBase } from '@/config/schemas/study-case'
import {
  CalculationModes,
  getValidStudyCaseWithConfig,
  StudyCaseId,
  StudyCaseWithConfig
} from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { useStudyCaseStore } from '@/stores/study-case'
import { useSystemStore } from '@/stores/system'
import { copy } from '@/util'
import StudyCaseOperationStatesTab from '@/views/project/study-cases/operation-states/StudyCaseOperationStatesTab.vue'
import StudyCaseBaseTab from '@/views/project/study-cases/StudyCaseBaseTab.vue'
import StudyCaseSuperpositionForm from '@/views/project/study-cases/superposition/StudyCaseSuperpositionForm.vue'
import type { OperationState } from '@gridside/hsb-api'
import { StudyCase, System } from '@gridside/hsb-api'
import type { TabConfig } from '@prionect/ui/dist/types/components/tabs/PTabs.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'StudyCaseForm',
  components: {
    StudyCaseSuperpositionForm,
    StudyCaseOperationStatesTab,
    OperationStatesTable,
    StudyCaseBaseTab,
    CalculationModeSwitcher
  },

  data: () => ({
    CalculationModes,
    fieldConfig: studyCase
  }),

  emits: {
    submit: (val: StudyCase) => val,
    cancel: (val: any) => val
  },

  setup(_, { emit }) {
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()
    const calculationStore = useCalculationStore()
    const { projectId } = useProject()
    studyCaseStore.ensureLoaded(projectId.value)
    systemStore.ensureLoaded(projectId.value)
    calculationStore.ensureLoaded(projectId.value)

    const tabs = ref<TabConfig[]>([
      {
        id: 'base',
        label: 'Allgemein',
        error: false
      },
      {
        id: 'operationStates',
        label: 'Betriebszustände',
        error: false
      },
      {
        id: 'superposition',
        label: 'Überlagerung',
        error: false
      }
    ])

    /**
     * Setup route item
     */
    const route = useRoute()
    const routeId = computed(() => route.params[RouteParams.StudyCaseId] as StudyCaseId | undefined)
    const routeItem = computed(() =>
      routeId.value ? studyCaseStore.findById(routeId.value) : undefined
    )

    /**
     * Setup vee-validate Form
     * - merge initial values with default values
     * - setup dynamic validation based on selected calculationMode
     * (Attention: "validationSchema" overrides rules on <Field>)
     */
    const initialValues = getValidStudyCaseWithConfig(routeItem.value)
    const calculationMode = ref<CalculationModes>(initialValues.configuration.modeSelected)
    watch(calculationMode, (value) =>
      studyCaseForm.setFieldValue('configuration.modeSelected', value)
    )
    const validationSchema = computed(() => {
      const schema = StudyCaseSchemaBase.extend({
        configuration: CalculationModesSchemas[calculationMode.value]
      })
      return toTypedSchema(schema)
    })
    const studyCaseForm = useForm<StudyCaseWithConfig>({
      initialValues,
      validationSchema,
      keepValuesOnUnmount: true // because of switching modes
    })
    // Make form accessible in child components
    provide('studyCaseForm', studyCaseForm)

    /**
     * Handle tab errors
     */
    watch(
      () => studyCaseForm.errors.value,
      (value) => {
        // don't show errors when never touched
        if (!studyCaseForm.meta.value.touched) {
          return
        }
        const errors = Object.keys(value)
        tabs.value[2].error = errors.some((key) => key.startsWith('superposition'))
        tabs.value[1].error = errors.some((key) => key.startsWith('configuration'))
      }
    )

    /**
     * Load StudyCase when route param changes.
     * Use resetForm + force to reset initial values instead of merging
     * See: https://vee-validate.logaretm.com/v4/api/use-form/#api-reference
     */
    watch(routeItem, (value) => {
      const studyCaseWithConfig = getValidStudyCaseWithConfig(value)
      studyCaseForm.resetForm({ values: studyCaseWithConfig }, { force: true })
    })

    /**
     * Emit StudyCase with operation states based on submitted form
     */
    const studyCaseFormSubmit = studyCaseForm.handleSubmit((values) => {
      const studyCase: StudyCaseWithConfig = copy(values)
      if (studyCase.configuration.modeSelected == CalculationModes.MANUAL) {
        studyCase.operationStates = studyCase.configuration.manual
        emit('submit', studyCase)
        return
      }

      // Auto Fail
      if (studyCase.configuration.modeSelected == CalculationModes.AUTO_FAIL) {
        const operationStates: OperationState[] = []
        studyCase.configuration.autoFail.forEach((autoFail) => {
          const system = systemStore.findById(autoFail.system) as System
          let setIndex = 0
          for (let indexRow = 0; indexRow < system.wireCount; indexRow++) {
            const operationState: OperationState = {
              system: autoFail.system,
              conductorStates: []
            }
            for (let indexCol = 0; indexCol < system.wireCount; indexCol++) {
              operationState.conductorStates.push({
                current: setIndex === indexCol ? autoFail.current : 0,
                angle: 0
              })
            }
            setIndex++
            operationStates.push(operationState)
          }
        })
        studyCase.operationStates = operationStates
      }

      // Auto AWE
      if (studyCase.configuration.modeSelected == CalculationModes.AUTO_AWE) {
        const operationStates: OperationState[] = []
        studyCase.configuration.autoAWE.forEach((autoAWE) => {
          const system = systemStore.findById(autoAWE.system) as System
          const AWEAngle = autoAWE.angle
          const AWECurrent = autoAWE.current

          // construct a conductor state for each wire/phase on the system
          for (let AWEPhase = 0; AWEPhase < system.wireCount; AWEPhase++) {
            const operationState: OperationState = {
              system: autoAWE.system,
              conductorStates: []
            }
            for (let phase = 0; phase < system.wireCount; phase++) {
              let angle = 0
              if (AWEPhase === 0 && phase === 2) {
                angle = -AWEAngle
              } else if (AWEPhase === 1 && phase === 0) {
                angle = -AWEAngle
              } else if (AWEPhase === 2 && phase === 1) {
                angle = -AWEAngle
              } else {
                angle = 0
              }
              operationState.conductorStates.push({
                current: AWEPhase === phase ? 0 : AWECurrent,
                angle
              })
            }
            operationStates.push(operationState)
          }
        })
        studyCase.operationStates = operationStates
      }

      // Sync generated operation states to manual
      studyCase.configuration.manual = studyCase.operationStates.map((opState) => ({
        ...opState,
        mirrored: false
      }))
      studyCaseForm.setFieldValue('operationStates', studyCase.operationStates)
      emit('submit', studyCase)
    })

    return {
      studyCaseStore,
      tabs,
      calculationMode,
      studyCaseForm,
      studyCaseFormSubmit,
      routeItem,
      calculationStore,
      projectId
    }
  },

  computed: {
    submitButtonDisabled() {
      return this.studyCaseForm.isSubmitting.value
    }
  },
  methods: {
    copy,
    async handleStartCalculation() {
      if (!this.routeItem) {
        return
      }
      const calculation = await this.calculationStore.start({
        studyCaseId: this.routeItem.id,
        project: this.projectId
      })
      this.$router.push({
        name: 'project-map',
        query: { calculationId: calculation.id, studyCaseId: this.routeItem.id }
      })
    }
  }
})
</script>

<template>
  <form v-if="studyCaseStore.loaded" @submit="studyCaseFormSubmit">
    <p-field v-bind="fieldConfig.name" />

    <p-tabs :tabs="tabs">
      <!-- Base properties -->
      <template #tab:base>
        <StudyCaseBaseTab />
      </template>

      <!-- Operation States -->
      <template #tab:operationStates>
        <StudyCaseOperationStatesTab v-model:calculation-mode="calculationMode" />
      </template>

      <!-- superposition -->
      <template #tab:superposition>
        <StudyCaseSuperpositionForm />
      </template>
    </p-tabs>

    <div class="py-4 flex">
      <el-button
        type="success"
        :loading="studyCaseForm.isSubmitting.value"
        :disabled="submitButtonDisabled"
        @click="studyCaseFormSubmit"
      >
        Speichern
      </el-button>
      <el-button text @click="$emit('cancel', true)">Abbrechen</el-button>
      <div class="flex-grow"></div>
      <el-button :disabled="routeItem === undefined" @click="handleStartCalculation">
        Berechnung starten
      </el-button>
    </div>
  </form>
</template>
<style scoped></style>
