<script setup lang="ts">
import SystemSelector from '@/components/study-case/SystemSelector.vue'
import { ManualConfig } from '@/config/schemas/study-case'
import { StudyCaseWithConfig } from '@/model'
import { ConductorState, System } from '@gridside/hsb-api'
import { FormContext, useFieldArray } from 'vee-validate'
import { inject } from 'vue'

const form = inject<FormContext<StudyCaseWithConfig>>('studyCaseForm')
const { fields, push } = useFieldArray<ManualConfig>('configuration.manual')

function createConductorStates(system: System): ConductorState[] {
  const AnglePresets = [
    [0, 180],
    [0, -120, 120]
  ] as const

  const anglePreset = AnglePresets.find((preset) => preset.length === system.wireCount)
  return Array.from({ length: system.wireCount }).map((_, index) => {
    return {
      current: 0,
      angle: anglePreset?.[index] ?? 0
    }
  })
}

const addSystem = (system: System) => {
  push({
    system: system.id,
    conductorStates: createConductorStates(system),
    mirrored: true
  })
}
</script>

<template>
  <p-form-section title="Betriebszustände manuell festlegen">
    <p class="text-gray-400 mb-4">
      Wählen Sie Systeme aus und legen Sie für jedes System die Ströme und Phasenwinkel fest:
    </p>

    <OperationStatesTable v-if="fields.length > 0" class="w-full study-case-table" />
    <SystemSelector
      :has-error="fields.length === 0 && form?.meta.value.touched"
      @click="addSystem"
    />
  </p-form-section>
</template>

<style scoped lang="css"></style>
