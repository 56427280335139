<script lang="ts">
import { HsbApi } from '@/api'
import { useProject } from '@/composables/useProject'
import { HsbLibObjectEnum } from '@/model/calculation'
import { defineComponent } from 'vue'

const CalculationApi = HsbApi.calculation

export default defineComponent({
  name: 'ExportTab',

  data() {
    return {
      HsbLibObjectEnum: HsbLibObjectEnum,
      pendingDownload: false
    }
  },

  setup() {
    const { projectId } = useProject()
    return {
      projectId
    }
  },

  methods: {
    async downloadResults(type: HsbLibObjectEnum) {
      throw Error('TODO 670 studycase-calculation-operationalmode')
      // try {
      //   this.pendingDownload = true
      //   const response = (await CalculationApi.getCalculationHsbLibObject(
      //     this.projectId,
      //     type
      //   )) as BlobPart
      //   triggerDownload(
      //     response,
      //     `${this.projectId}_${type}_${Intl.DateTimeFormat('de', {
      //       dateStyle: 'short'
      //     })
      //       .format(new Date())
      //       .replaceAll('.', '_')}`,
      //     'application/json'
      //   )
      // } catch (e) {
      //   ElMessage({
      //     message: 'Daten nicht verfügbar',
      //     duration: 5000
      //   })
      // } finally {
      //   this.pendingDownload = false
      // }
    }
  }
})
</script>

<template>
  <div>
    <h3 class="font-medium text-md text-gray-500 my-2">HSBlib-Berechnungsdaten:</h3>

    <el-button
      :loading="pendingDownload"
      class="grow h-auto"
      icon="DownloadIcon"
      size="default"
      @click="downloadResults(HsbLibObjectEnum.MESHED_MEDIUM_SIMULATION)"
    >
      Meshed Medium Simulation
    </el-button>

    <el-button
      :loading="pendingDownload"
      class="grow h-auto"
      icon="DownloadIcon"
      size="default"
      @click="downloadResults(HsbLibObjectEnum.OVERHEADLINE)"
    >
      OverheadLine
    </el-button>
  </div>
</template>

<style scoped lang="css"></style>
