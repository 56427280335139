import { HsbApi } from '@/api'
import { ProjectId, StudyCaseId } from '@/model'
import {
  CalculationResult,
  CalculationStatus,
  CalculationStatusValue,
  CalculationId
} from '@/model/calculation'
import { CalculationLogMessage } from '@gridside/hsb-api'
import { useCorridorStore } from '@/stores/corridor'
import { csvToJson } from '@/util'
import { FeatureCollection } from 'geojson'
import { defineStore } from 'pinia'
import { useStudyCaseStore } from '@/stores/study-case'

type CalculationStoreState = {
  calculationStatus: Partial<CalculationStatus>
  initialized: boolean
  limitVoltage?: number
  loadingResult: boolean
  log: CalculationLogMessage[]
  projectId?: ProjectId
  result?: CalculationResult
  showLog: boolean
  showResult: boolean
}

type CalculationStartPayload = {
  project: ProjectId
  studyCaseId: StudyCaseId
}

const CalculationApi = HsbApi.calculation
const StudyCaseApi = HsbApi.studyCases

export const useCalculationStore = defineStore('calculation', {
  state: (): CalculationStoreState => {
    return {
      calculationStatus: {
        studyCase: undefined,
        progress: undefined,
        project: undefined,
        status: 'INACTIVE'
      },
      initialized: false,
      limitVoltage: undefined,
      loadingResult: false,
      log: [],
      projectId: undefined,
      result: undefined as CalculationResult | undefined,
      showLog: false,
      showResult: false
    }
  },

  getters: {
    isProcessing(): boolean {
      return ['PENDING', 'INITIALIZING', 'RUNNING'].includes(
        this.calculationStatus?.status || 'INACTIVE'
      )
    },

    studyCase(): StudyCaseId | undefined {
      return this.calculationStatus?.studyCase
    },

    resultEmf(): any {
      return csvToJson(this.result?.result.eMF || '')
    },

    resultEmfGeoJSON(): FeatureCollection | undefined {
      return this.result?.result.emfResult as FeatureCollection | undefined
    },

    resultLoadFlow(): any {
      return csvToJson(this.result?.result.loadFlow || '')
    },

    resultLoadFlowGeoJSON(): FeatureCollection | undefined {
      return this.result?.result.loadFlowGeojson as FeatureCollection | undefined
    },

    resultRelations(): FeatureCollection | undefined {
      return this.result?.result.relations as FeatureCollection | undefined
    },

    resultVoltage(): number | undefined {
      return this.result?.result.resultVoltage
    },

    status(): CalculationStatusValue {
      return this.calculationStatus?.status || 'INACTIVE'
    }
  },

  actions: {
    reset() {
      const wasInitialized = this.initialized
      this.$reset()
      this.initialized = wasInitialized
    },

    init() {
      if (!this.initialized) {
        // Register listener functions on websocket
        CalculationApi.onStatusUpdated(async (status) => {
          // ignore updates for other projects
          if (status.project !== this.projectId) {
            return
          }

          this.calculationStatus = status
          if (this.status === 'FINISHED') {
            await this.loadResult()
          }

          if (
            this.projectId &&
            status.status === 'INACTIVE' &&
            status.message === 'container running'
          ) {
            // corridor is now available (the worker container was not started before)
            useCorridorStore().load(this.projectId)
          }
        })

        CalculationApi.onLogUpdated((entry) => {
          // ignore updates for other projects
          if (entry.project === this.projectId) {
            this.log.push(entry)
          }
        })

        HsbApi.result.onInvalidated((data) => {
          if (data.project === this.projectId) {
            this.reset()
            // project stays the same
            this.projectId = data.project
          }
        })

        this.initialized = true
      }
    },

    async loadResult(calculationId?: CalculationId) {
      // TODO calculationId
      if (!calculationId) {
        console.warn('IMPLEMENT CALCULATION ID')
        return
      }
      const projectId = this.calculationStatus?.project

      if (projectId) {
        this.result =
          (await CalculationApi.getCalculationResult(projectId, calculationId)) || undefined
      }
    },

    async start(payload: CalculationStartPayload) {
      const { project, studyCaseId } = payload
      this.log = []
      this.showLog = true
      this.result = undefined

      const studyCaseStore = useStudyCaseStore()
      await studyCaseStore.ensureLoaded(project)
      const studyCase = studyCaseStore.findById(studyCaseId)
      if (studyCase) {
        this.limitVoltage = studyCase.limitVoltage
      }

      const calc = await StudyCaseApi.startStudyCaseCalculation(project, studyCaseId)
      this.calculationStatus.status = calc.state
      return calc
    },

    async ensureLoaded(project: ProjectId, calculationId?: CalculationId) {
      // TODO calculationId
      if (!calculationId) {
        console.warn('IMPLEMENT CALCULATION ID')
        return
      }

      this.projectId = project

      if (project === this.calculationStatus?.project) {
        return
      }

      // Load results independently of the calculation status (which is worker-dependent)
      if (!this.loadingResult) {
        // avoid race conditions
        this.loadingResult = true

        CalculationApi.getCalculationResult(project, calculationId)
          .then((data) => {
            if (data !== null) {
              this.result = data
            } else {
              this.result = undefined
            }
          })
          .finally(() => (this.loadingResult = false))
      }

      this.calculationStatus = await CalculationApi.getCalculationStatus(project, calculationId)

      const studyCaseStore = useStudyCaseStore()
      await studyCaseStore.ensureLoaded(project)
      const studyCaseId = this.calculationStatus?.studyCase

      if (studyCaseId) {
        const studyCase = studyCaseStore.findById(studyCaseId)
        if (studyCase) {
          this.limitVoltage = studyCase.limitVoltage
        }
      }

      this.log = await CalculationApi.getCalculationLog(project, true)
    }
  }
})
