<script setup lang="ts">
import SystemSelector from '@/components/study-case/SystemSelector.vue'
import SystemTag from '@/components/system/SystemTag.vue'
import { fieldsAWEConfig, fieldsFailConfig } from '@/config/fields/study-case'
import { AutoAWEConfig } from '@/config/schemas/study-case'
import { StudyCaseWithConfig } from '@/model'
import { useSystemStore } from '@/stores/system'
import { System } from '@gridside/hsb-api'
import { FormContext, useFieldArray } from 'vee-validate'
import { inject } from 'vue'

const systemStore = useSystemStore()
const form = inject<FormContext<StudyCaseWithConfig>>('studyCaseForm')
const { fields, remove, push } = useFieldArray<AutoAWEConfig>('configuration.autoAWE')

const addSystem = (system: System) => {
  push({
    system: system.id,
    current: 0,
    angle: 0
  })
}
</script>

<template>
  <p-form-section title="AWE-Ströme und Winkeldifferenzen">
    <span class="text-gray-500">
      Legen Sie fest, welchen Strom die verbleibenden Leiter während einer automatischen
      Wiedereinschaltung (AWE) führen und welche Differenz ihre Phasenwinkel haben.
    </span>

    <div class="table-simple table-simple--dividers table-auto">
      <table class="w-full">
        <thead>
          <tr>
            <th class="w-8"></th>
            <th>System</th>
            <th style="width: 150px">AWE-Strom</th>
            <th style="width: 150px">Winkel</th>
            <th style="width: 60px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rowField, rowOpIndex) in fields" :key="rowOpIndex">
            <!-- System Tag -->
            <td>
              <SystemTag :system="rowField.value.system" type="small" class="font-bold">
                {{ rowOpIndex + 1 }}
              </SystemTag>
            </td>

            <!-- System name -->
            <td class="font-bold">
              {{ systemStore.systemName(rowField.value.system) }}
            </td>

            <!-- Current -->
            <td>
              <p-field
                v-bind="fieldsFailConfig.current"
                :name="`configuration.autoAWE.${rowOpIndex}.${fieldsAWEConfig.current.name}`"
                label=""
              />
            </td>

            <!-- Angle -->
            <td>
              <p-field
                v-bind="fieldsAWEConfig.angle"
                :name="`configuration.autoAWE.${rowOpIndex}.${fieldsAWEConfig.angle.name}`"
                label=""
              />
            </td>

            <!-- Actions -->
            <td>
              <!-- Remove -->
              <el-button class="inline-block !m-0" text circle @click="remove(rowOpIndex)">
                <RemoveIcon />
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <SystemSelector
      :has-error="fields.length === 0 && form?.meta.value.touched"
      @click="addSystem"
    />
  </p-form-section>
</template>

<style scoped lang="css">
table {
  td {
    vertical-align: top;
  }
}
</style>
