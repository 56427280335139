<script lang="ts">
import { useProject } from '@/composables/useProject'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { RouteParams } from '@/router/routeParams'
import { useStudyCaseStore } from '@/stores/study-case'
import { useSystemStore } from '@/stores/system'
import StudyCaseForm from '@/views/project/study-cases/StudyCaseForm.vue'
import { StudyCase } from '@gridside/hsb-api'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'StudyCaseDetail',
  components: { StudyCaseForm, DetailPanel },

  setup() {
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()
    const { projectId } = useProject()
    systemStore.ensureLoaded(projectId.value)
    return { studyCaseStore, systemStore, projectId }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.$route.params[RouteParams.StudyCaseId]
    },

    title(): string {
      if (this.create) {
        return 'Neuer Berechnungsfall'
      } else {
        return 'Berechnungsfall bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-study-cases' }
      )
    },
    async onSave(item: StudyCase) {
      this.saving = true
      try {
        const updated = await this.studyCaseStore.save({ ...item })

        // reselect item (because it gets deselected by <el-table>...)
        this.$nextTick(() => {
          this.studyCaseStore.selection = [updated.id]
        })

        if (this.create) {
          this.$router.push({
            name: 'project-study-case-edit',
            params: { studyCaseId: updated.id }
          })
        }

        ElMessage.success(
          this.create
            ? 'Betriebsfall wurde erfolgreich angelegt.'
            : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <StudyCaseForm
      v-if="systemStore.loadedProject == projectId"
      :saving="saving"
      @cancel="onClose"
      @submit="onSave"
    />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
