import { WebSocket } from '@/api'
import { CalculationStatus } from '@/model/calculation'
import { CalculationApi, CalculationLogMessage, Configuration } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class CalculationExtendedApi extends CalculationApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onLogUpdated(handler: (log: CalculationLogMessage) => void) {
    return this.webSocket.subscribe('Calculation.logUpdated', handler)
  }

  onStatusUpdated(handler: (status: CalculationStatus) => void) {
    return this.webSocket.subscribe('Calculation.statusUpdated', handler)
  }
}
