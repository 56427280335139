<script lang="ts" setup>
import { useCalculationStore } from '@/stores/calculation'
import { LogLevel } from '@gridside/hsb-api'
import { computed, nextTick, ref, watch } from 'vue'

const store = useCalculationStore()
const logEl = ref<HTMLDivElement | undefined>(undefined)

const log = computed(() => store.log)
const logLength = computed(() => log.value.length)

const scrollToEnd = () => {
  logEl.value?.scrollTo({ top: logEl.value?.scrollHeight + 1000 })
}

watch(logLength, () => {
  nextTick(scrollToEnd)
})

watch(logEl, () => {
  nextTick(scrollToEnd)
})

const colors: Record<LogLevel, string> = {
  [LogLevel.FATAL]: '#ffdddd',
  [LogLevel.CRITICAL]: '#ffdddd',
  [LogLevel.ERROR]: '#ffcccc',
  [LogLevel.WARNING]: '#ffeecc',
  [LogLevel.WARN]: '#ffeecc',
  [LogLevel.INFO]: '#f6f6ff',
  [LogLevel.DEBUG]: 'transparent',
  [LogLevel.NOTSET]: 'transparent'
}
</script>

<template>
  <div
    ref="logEl"
    class="text-xs overflow-y-auto flex-1 whitespace-pre font-mono leading-none text-gray-500 bg-white p-2"
  >
    <div
      v-for="(logItem, i) in store.log"
      :key="i"
      :style="`background-color: ${colors[logItem.level] || 'transparent'}`"
      style="margin-bottom: 2px"
    >
      [{{ logItem.level }}] {{ logItem.data.replaceAll('\r', '\n') }}
    </div>
  </div>
</template>

<style scoped lang="css"></style>
