import { FieldConfig, NumberFieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { ConductorState, StudyCase } from '@gridside/hsb-api'
import type { OperationState } from '@gridside/hsb-api/dist/models/OperationState'
import { AutoAWEConfig, AutoFailConfig } from '@/config/schemas/study-case'
import { SelectFieldConfig } from '@prionect/ui'

type FieldValidationKeys = keyof Omit<StudyCase, 'id' | 'operationStates'>
const fields = {
  name: {
    name: 'name',
    label: 'Name',
    min: 1,
    required: 'Bitte geben Sie einen Namen ein.'
  },
  superposition: {
    name: 'superposition',
    type: 'textarea',
    required: true
  },
  description: {
    name: 'description',
    type: 'textarea',
    label: 'Beschreibung',
    required: false
  },
  configuration: {
    name: 'configuration',
    required: false
  },
  expectationFactor: {
    name: 'expectationFactor',
    label: 'Erwartungsfaktor',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
    required: true,
    minFractionDigits: 1
  },
  limitVoltage: {
    name: 'limitVoltage',
    label: 'Grenzwert',
    type: 'number',
    min: 0,
    step: 10,
    required: true,
    unit: 'V'
  }
} satisfies Record<FieldValidationKeys, FieldConfig>
type OperationStateValidationKeys = keyof Omit<OperationState, 'conductorStates'>
const fieldsOperationState: Record<OperationStateValidationKeys, FieldConfig> = {
  system: templateSystem()
}
type ConductorStateValidationKeys = keyof ConductorState

function templateSystem() {
  return {
    name: 'system',
    required: true,
    type: 'select',
    items: [], // filled in runtime
    label: 'System'
  } satisfies SelectFieldConfig
}
function templateCurrent(label: string) {
  return {
    name: 'current', // defined at runtime
    label,
    type: 'number',
    min: 0,
    required: true,
    unit: 'A'
  } satisfies NumberFieldConfig
}
function templateAngle(label: string) {
  return {
    name: 'angle', // defined at runtime
    label,
    type: 'number',
    required: true,
    min: -360,
    max: 360,
    step: 30,
    unit: '°'
  } satisfies NumberFieldConfig
}
const fieldsConductorState = {
  current: templateCurrent('Strom'),
  angle: templateAngle('Phase/Winkel')
} satisfies Record<ConductorStateValidationKeys, FieldConfig>

const fieldsFailConfig = {
  system: templateSystem(),
  current: templateCurrent('Fehlerstrom')
} satisfies Record<keyof AutoFailConfig, FieldConfig>
const fieldsAWEConfig = {
  system: templateSystem(),
  current: templateCurrent('AWE-Strom'),
  angle: templateAngle('Winkel')
} satisfies Record<keyof AutoAWEConfig, FieldConfig>

export { fields, fieldsConductorState, fieldsOperationState, fieldsFailConfig, fieldsAWEConfig }
export default fields
