<script lang="ts">
import { defineComponent } from 'vue'
import AppBar from '@/components/app/AppBar.vue'

export default defineComponent({
  name: 'AppLayout',
  components: { AppBar },
  setup() {
    return {}
  },

  computed: {
    hasMenuComponent(): boolean {
      return !!(this.$route.matched?.length > 0 && this.$route.matched[0].components?.menu)
    }
  }
})
</script>

<template>
  <div class="flex flex-col h-screen overflow-hidden">
    <AppBar class="app-bar"></AppBar>
    <div class="app-content flex-1 flex w-full items-stretch overflow-hidden bg-gray-100">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="css">
.fullscreen .app-bar {
  display: none !important;
}
</style>
