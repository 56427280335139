import { StudyCase } from '@gridside/hsb-api'
import { StudyCaseConfig } from '@/config/schemas/study-case'
import { v4 } from 'uuid'
import deepmerge from 'deepmerge'
import { copy } from '@/util'

export type StudyCaseId = StudyCase['id']

export enum CalculationModes {
  MANUAL = 'manual',
  AUTO_FAIL = 'auto_fail',
  AUTO_AWE = 'auto_awe'
}
export const CalculationModesTranslation: { [key in CalculationModes]: string } = {
  [CalculationModes.MANUAL]: 'manuell',
  [CalculationModes.AUTO_FAIL]: 'automatisch (Fehlerfall)',
  [CalculationModes.AUTO_AWE]: 'automatisch (AWE-Fall)'
}

export type StudyCaseWithConfig = StudyCase & {
  configuration: StudyCaseConfig
}

export function createDefaultStudyCaseConfig(): StudyCaseConfig {
  return {
    modeSelected: CalculationModes.MANUAL,
    autoAWE: [],
    autoFail: [],
    manual: []
  }
}

export function createDefaultStudyCase(): StudyCaseWithConfig {
  return {
    id: v4(),
    name: '',
    description: '',
    configuration: createDefaultStudyCaseConfig(),
    limitVoltage: 60,
    expectationFactor: 1,
    superposition: '',
    operationStates: []
  }
}

/**
 * Merge initial values with default values
 * @param item
 */
export function getValidStudyCaseWithConfig(item?: StudyCase): StudyCaseWithConfig {
  let studyCase = createDefaultStudyCase()
  if (item) {
    studyCase = deepmerge(studyCase, item, {
      arrayMerge: (_, source) => {
        return copy(source)
      }
    }) as StudyCaseWithConfig
  }
  return studyCase
}
