<script lang="ts" setup>
import { useCalculationStore } from '@/stores/calculation'
import ResultTable from './ResultTable.vue'
import { computed } from 'vue'
import { useProject } from '@/composables/useProject'

const store = useCalculationStore()

const { project } = useProject()
const isPipeProject = computed(() => project.value?.mediaType === 'pipe')
</script>

<template>
  <ResultTable v-if="isPipeProject" :items="store.resultLoadFlow" />
  <ResultTable v-else :items="store.resultEmf" />
</template>

<style lang="css" scoped></style>
