<script lang="ts">
import CalculationLog from '@/components/map/controls/calculation/CalculationLog.vue'
import ExportTab from '@/components/map/controls/calculation/ExportTab.vue'
import TablesTab from '@/components/map/controls/calculation/TableTab.vue'
import { useCalculationStore } from '@/stores/calculation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ResultTabs',
  components: { TablesTab, ExportTab, CalculationLog },

  setup() {
    return {
      calculationStore: useCalculationStore()
    }
  },

  computed: {
    hasResult() {
      return !!this.calculationStore.result
    },

    tabs() {
      const tabs = {
        log: { id: 'log', label: 'Log' },
        tables: { id: 'tables', label: 'Tabelle' },
        export: { id: 'export', label: 'Export' }
      }
      if (this.hasResult) {
        return [tabs.log, tabs.tables, tabs.export]
      } else {
        return [tabs.log]
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col">
    <div class="text-sm font-semibold text-gray-400 uppercase tracking-wide">Ergebnis</div>

    <p-tabs :tabs="tabs" class="flex-1 flex flex-col overflow-hidden">
      <template #tab:log>
        <div class="flex flex-col flex-1 h-full">
          <CalculationLog />
        </div>
      </template>
      <template #tab:tables>
        <TablesTab />
      </template>
      <template #tab:export>
        <ExportTab />
      </template>
    </p-tabs>
  </div>
</template>

<style scoped lang="css">
:deep(.el-tabs__content) {
  @apply flex-1 flex flex-col;
}

:deep(.el-tab-pane) {
  @apply overflow-scroll max-h-full flex-1;
}
</style>
