<script lang="ts">
import { RouteParams } from '@/router/routeParams'
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useSystemStore } from '@/stores/system'
import { ProjectId, System } from '@/model'
import { useRoute } from 'vue-router'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import SystemTag from '@/components/system/SystemTag.vue'

export default defineComponent({
  name: 'SystemListView',
  components: { SystemTag, EntityTableView },
  setup() {
    const route = useRoute()
    const systemStore = useSystemStore()
    const { duplicateItems } = useDuplicate(systemStore.save)
    const { deleteItems } = useDelete(systemStore.delete, {
      check: (items: System[]) =>
        items.some((item) => item.id === route.params[RouteParams.SystemId]),
      routeName: 'project-systems'
    })

    return { systemStore, duplicateItems, deleteItems }
  },

  computed: {
    items() {
      return Object.values(this.systemStore.itemsById)
    },
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    }
  },

  mounted() {
    this.systemStore.load(this.projectId)
  },

  methods: {
    asSystem(item: any): System {
      return item as System
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="systemStore.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neues System"
    confirm-delete-label="System löschen"
    default-sort="name"
    empty-text="Sie haben bisher keine Systeme angelegt."
    :items="items"
    :loading="systemStore.loading"
    :search-properties="['name']"
    title="Systeme"
    @create="$router.push({ name: 'project-system-create' })"
    @edit="$router.push({ name: 'project-system-edit', params: { systemId: $event } })"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
  >
    <template #columns>
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold">
        <template #default="{ row }">
          <div class="flex gap-2">
            <SystemTag type="small" :system="asSystem(row)"></SystemTag>
            <span class="block h-5">
              {{ asSystem(row).name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="wireCount" label="Leiteranzahl" sortable />
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Systeme angelegt.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Ein System ist eine Stromverbindung zwischen zwei Umspannwerken. Ein System kann aus
        mehreren Leitern bestehen (meist 3). Die Masten einer Freileitung können mehrere Systeme
        tragen.
      </p>

      <p-btn @click="$router.push({ name: 'project-system-create' })">Neues System anlegen</p-btn>
    </template>

    <template #confirm-delete="{ items }">
      <div v-if="items.length === 1">
        Wollen Sie das System
        <b>{{ asSystem(items[0]).name }}</b>
        wirklich löschen?
      </div>
      <div v-else>
        Wollen Sie diese Systeme wirklich löschen?
        <ul>
          <li v-for="item in items" :key="item.id">
            <b>{{ asSystem(item).name }}</b>
          </li>
        </ul>
      </div>
    </template>
  </EntityTableView>
</template>

le>
